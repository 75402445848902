<template>
  <div class="panelbox">
    <LeftPannel :image="this.$store.state.user.image" :cname="this.$store.state.user.cname" />
    <div :class="type === 'admin' ? 'right-panel' : 'right-panel staff-section-page'
      ">
      <div class="header">
        <CommonHeader v-if="type === 'admin'" title="Edit Leave Application Form" :name="this.$store.state.user.name" />
        <staff-header v-else title="Add Project"></staff-header>
      </div>
      <div class="content-page">
        <div class="fullrow">
          <div class="row">
            <div class="col-6">
              <div class="back-button-setup">
                <router-link :to="{ name: 'leave' }">
                  <img src="../../assets/images/back-left-arrow.png" alt="&laquo;" />Back
                </router-link>
              </div>
            </div>
            <div class="col-12">
              <div class="common-white-shadow-background">
                <div class="edit-staff-data-form leave-application-form">
                  <form @submit.prevent class="form-box row">
                    <!-- Leave Reason -->
                    <div class="col-12">
                      <h3 class="leave-from-radio-label">Reason For Leave</h3>
                      <div style="margin-top: 10px" class="leave-from-radio-options">
                        <input type="radio" id="emergency_leave" name="leave_type" v-model="formData.leave_type"
                          value="emergency" />
                        <label for="emergency_leave">Emergency Leave</label>
                        <input type="radio" id="annual_leave" name="leave_type" v-model="formData.leave_type"
                          value="annual" />
                        <label for="annual_leave">Annual Leave</label>
                        <input type="radio" id="other" name="leave_type" v-model="formData.leave_type" value="other" />
                        <label for="other">Other</label>
                      </div>
                      <p style="color: red">{{ errors.leave_type }}</p>
                    </div>

                    <!-- Start Date -->
                    <div class="col-sm-6 setupdate">
                      <input type="date" name="fday_absence" placeholder="First day of absence"
                        v-model="formData.start_date" @change="dateDifference" required />
                      <p style="color: red">{{ errors.start_date }}</p>
                    </div>
                    <div class="col-sm-6 setupdate">
                      <input type="date" name="lday_absence" placeholder="Last day of absence"
                        v-model="formData.end_date" @change="dateDifference" :min="formData.start_date" required />
                      <p style="color: red">{{ errors.end_date }}</p>
                    </div>

                    <!-- Select Paid Leave Dates -->
                    <div class="col-12">
                      <h3 class="leave-from-radio-label">
                        Select Paid Leave Dates
                      </h3>
                      <div style="margin-top: 10px">
                        <div v-for="(dateObj, index) in datesInRange" :key="index">
                          <label>
                            <input type="checkbox" v-model="dateObj.checked" @change="updatePaidLeaveDates" />
                            {{ dateObj.formattedDate }}
                          </label>
                        </div>
                      </div>
                    </div>

                    <!-- Paid Days -->
                    <div style="margin-top: 15px" class="col-sm-6">
                      <h3 style="margin-bottom: 10px" class="leave-from-radio-label">
                        Paid Days
                      </h3>
                      <input type="text" v-model="formData.paid_days" placeholder="No. of paid days" readonly />
                    </div>

                    <!-- Half Leave Checkbox -->
                    <div style="
                        margin-top: 15px;
                        display: flex;
                        align-items: center;
                      " class="col-sm-6 setupdate">
                      <label for="half_leave" class="leave-from-radio-label">Half Leave</label>
                      <input style="margin-left: 5px" id="half_leave" type="checkbox" name="half_leave"
                        v-model="formData.half_leave" @change="dateDifference" />
                    </div>

                    <!-- Total Days Requested -->
                    <div class="col-sm-6">
                      <h3 style="margin-bottom: 10px" class="leave-from-radio-label">
                        No. of days requested
                      </h3>
                      <input type="text" v-model="formData.total_day" placeholder="No. of days requested" readonly />
                    </div>

                    <!-- Staff Name -->
                    <div class="col-sm-6">
                      <h3 style="margin-bottom: 10px" class="leave-from-radio-label">
                        Staff Name
                      </h3>
                      <select v-model="formData.staffid" required>
                        <option value="" disabled selected>Select Name</option>
                        <option v-for="data in names" :value="data.id" :key="data.id">
                          {{ data.name }}
                        </option>
                      </select>
                      <p style="color: red">{{ errors.staffid }}</p>
                    </div>

                    <!-- Comments -->
                    <div class="col-12">
                      <textarea name="leave-comments" rows="3" placeholder="Comments" v-model="formData.comments"
                        required></textarea>
                      <p style="color: red">{{ errors.comments }}</p>
                    </div>


                    <div>
                      <!-- Other form fields -->

                      <div style="display: flex; justify-content: center" class="status-buttons">
                        <button :style="getButtonStyle('approve')" @click="updateStatus('approve')">
                          Approve
                        </button>
                        <button style="margin-left: 5px;" :style="getButtonStyle('reject')"
                          @click="updateStatus('reject')">
                          Reject
                        </button>

                      </div>
                    </div>





                    <!-- Submit Button -->
                    <div lass="col-12">
                      <input type="submit" value="Submit" class="yellow-button" @click="edit_leave" />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CommonFooter />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import LeftPannel from "../../components/admin/LeftPannel.vue";
import CommonHeader from "../../components/admin/CommonHeader.vue";
import CommonFooter from "../../components/CommonFooter.vue";
import StaffHeader from "../../components/user/CommonHeader.vue";
import base_url from "../../base_url.js";

export default {
  components: {
    LeftPannel,
    CommonHeader,
    CommonFooter,
    StaffHeader,
  },
  props: ["id"],
  data() {
    return {
      type: "",
      formData: {
        leave_type: "",
        half_leave: false,
        start_date: "",
        end_date: "",
        total_day: "",
        comments: "",
        is_status: 0,
        staffid: "",
        paid_leave_dates: [],
        paid_days: 0,
        unpaid_days: 0,
      },
      initialStatus: 0,
      buttonState: "",
      datesInRange: [],
      names: [],
      errors: {},
    };
  },
  beforeRouteEnter(to, from, next) {
    if (localStorage.getItem("type") === "staff") {
      if (localStorage.getItem("permission").includes("edit_admin_leave")) {
        next();
      } else {
        next({ name: "staff_dashboard" });
      }
    } else {
      next();
    }
  },
  created() {
    this.type = localStorage.getItem("type");
    this.dateDifference();
    this.getStaffById();
    this.getStaffName();
    if (this.$store.state.user.length == 0) {
      axios
        .post(`${base_url}/user`, {
          headers: { token: localStorage.getItem("token") },
          type: localStorage.getItem("type"),
        })
        .then((res) => {
          this.$store.state.user = res.data.user;
          this.getStaffName();
        });
    }
  },
  methods: {



    async getStaffName() {
      try {
        const response = await axios.post(
          `${base_url}/staffsfortrack/${this.$store.state.user.id}`
        );
        this.names = response.data;
      } catch (error) {
        console.error("Error fetching staff names:", error);
      }
    },

    async getStaffById() {
      try {
        const response = await axios.get(
          `${base_url}/get_staff_leave/${this.id}`
        );
        if (response.data && response.data.length > 0) {
          const leaveData = response.data[0];

          // Adjust the start_date and end_date directly
          // const startDateAdjusted = new Date(leaveData.start_date);
          // startDateAdjusted.setDate(startDateAdjusted.getDate() + 1);

          // const endDateAdjusted = new Date(leaveData.end_date);
          // endDateAdjusted.setDate(endDateAdjusted.getDate() + 1);

          this.formData = {
            leave_type: leaveData.leave_type,
            start_date: new Date(leaveData.start_date).toISOString().slice(0, 10),
            end_date: new Date(leaveData.end_date).toISOString().slice(0, 10),
            total_day: leaveData.total_day,
            comments: leaveData.comments,
            staffid: leaveData.staff_id,
            is_status: leaveData.is_status !== undefined ? leaveData.is_status : 0,
            half_leave: !Number.isInteger(leaveData.total_day),
            paid_leave_dates: leaveData.paid_leave_dates || [],
            paid_days: leaveData.paid_days || 0,
            unpaid_days: leaveData.unpaid_days || 0
          };
          // Store the initial status
          this.initialStatus = this.formData.is_status;

          // Determine initial button state based on is_status
          this.buttonState = this.initialStatus === 1 ? 'approve' : (this.initialStatus === 2 ? 'reject' : '');

          this.generateDatesInRange();
        }
      } catch (error) {
        console.error("Error fetching staff leave data:", error);
      }
    },
    updateStatus(status) {
      this.formData.is_status = status === 'approve' ? 1 : (status === 'reject' ? 2 : 0);
      this.buttonState = status; // Update button state for visual indication
    },
    getButtonStyle(status) {
      if (this.buttonState === status) {
        return {
          padding: '10px 20px',
          border: 'none',
          borderRadius: '5px',
          color: 'white',
          cursor: 'pointer',
          backgroundColor: status === 'approve' ? 'green' : 'red'
        };
      } else {
        return {
          padding: '10px 20px',
          border: 'none',
          borderRadius: '5px',
          color: 'white',
          cursor: 'pointer',
          backgroundColor: 'gray'
        };
      }
    },



    async edit_leave() {
      // Reset errors
      this.errors = {};

      // Validate required fields
      if (!this.formData.leave_type)
        this.errors.leave_type = "Leave Reason is Required";
      if (!this.formData.start_date)
        this.errors.start_date = "First Day is Required";
      if (!this.formData.end_date)
        this.errors.end_date = "Last Day is Required";
      if (!this.formData.comments)
        this.errors.comments = "Comments Required";
      if (!this.formData.staffid)
        this.errors.staffid = "Staff Name Required";

      // If there are no errors, proceed with API call
      if (Object.keys(this.errors).length === 0) {
        try {
          // Calculate unpaid days
          this.updatePaidLeaveDates();

          // Construct the data object
          const data = {
            staff_id: this.formData.staffid,
            admin_id: this.$store.state.user.id,
            leave_type: this.formData.leave_type,
            start_date: this.formData.start_date,
            end_date: this.formData.end_date,
            total_day: this.formData.total_day,
            comments: this.formData.comments,
            paid_leave_dates: this.formData.paid_leave_dates,
            paid_days: this.formData.paid_days,
            unpaid_days: this.formData.unpaid_days,
            is_status: this.formData.is_status !== undefined ? this.formData.is_status : this.initialStatus,
          };



          // Send data to the API
          await axios.put(`${base_url}/edit_staff_leave/${this.id}`, data);
          // Notify user and navigate to another page
          this.$notify({ title: "<b>Leave</b> Applied!", type: "warn" });
          this.$router.push("/leave");

        } catch (error) {
          console.error("Error updating leave:", error);
        }
      }
    },

    convertDate: function (cvt_date) {
      var date = new Date(cvt_date);
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var dt = date.getDate();
      if (dt < 10) {
        dt = "0" + dt;
      }
      if (month < 10) {
        month = "0" + month;
      }
      const cvt_date_formate = dt + "/" + month + "/" + year;
      return cvt_date_formate;
    },

    dateDifference() {
      const date1 = new Date(this.formData.start_date);
      const date2 = new Date(this.formData.end_date);
      if (date1 && date2) {
        const diffTime = Math.abs(date2 - date1);
        let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1;

        if (this.formData.half_leave) {
          diffDays -= 0.5;
        }

        this.formData.total_day = diffDays;
        this.generateDatesInRange(); // Update datesInRange when date changes
      }
    },
    generateDatesInRange() {
      this.datesInRange = [];
      const startDate = new Date(this.formData.start_date);
      const endDate = new Date(this.formData.end_date);
      let currentDate = startDate;
      while (currentDate <= endDate) {
        this.datesInRange.push({
          date: new Date(currentDate),
          formattedDate: `${currentDate.toLocaleDateString("en-GB", {
            weekday: "long",
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          })}`,
          checked: this.formData.paid_leave_dates.includes(
            currentDate.toISOString().substr(0, 10)
          ),
        });
        currentDate.setDate(currentDate.getDate() + 1);
      }
    },
    updatePaidLeaveDates() {
      // Update the paid leave dates
      this.formData.paid_leave_dates = this.datesInRange
        .filter(dateObj => dateObj.checked)
        .map(dateObj => dateObj.date.toISOString().substr(0, 10));

      // Update the count of paid days
      this.formData.paid_days = this.formData.paid_leave_dates.length;

      // Calculate the total number of dates in the range
      const totalDatesCount = this.datesInRange.length;

      // Calculate the count of unpaid days
      const unpaidDaysCount = totalDatesCount - this.formData.paid_days;

      // Update the formData with the count of unpaid days
      this.formData.unpaid_days = unpaidDaysCount;

      // Optional: Log the results for debugging
      console.log("Paid Leave Dates:", this.formData.paid_leave_dates);
      console.log("Paid Days Count:", this.formData.paid_days);
      console.log("Unpaid Days Count:", this.formData.unpaid_days);
    }
    ,
  },
};
</script>
